.container {
    position: relative;
    max-width: 970px;
}

.container img {
    max-width: 100%;
    max-height: 700px;
}

.invisible {
    display: none;
}
.floating {
    display: block;
    background-color: white;
    border-radius: 15px;
    border: 1px solid black;
    padding: 15px;
}