.container {
    max-width: 970px;
    margin: 0px auto;
    
}

div {
    vertical-align: middle;
}

#background {
    width: 100%;
    height: 100%;
    z-index: -10;
    position: absolute;
    background-color: darkgrey;
    
}

.hidden {
    display: none;
}