.small {
    width: 300px;
    height: 400px;
    display: inline-block;
    border: 1px solid darkgrey;
    border-radius: 15px;
    margin: 10px;
    text-align: center;
    vertical-align: top;
    overflow: hidden;
}

.small img {
    max-width: 100%;
    min-height: 75px; 
    max-height: 250px;
}