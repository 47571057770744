.container {
    position: relative;
}

.invisible {
    display: none;
}

.visible {
    display: default;
}
.floating {
    display: block;
    z-index: 100;
    background-color: white;
    border-radius: 15px;
    border: 1px solid black;
    padding: 15px;
    vertical-align: top;
}

.topalign {
    vertical-align: top;
}

.halfsize {
    width: 400px;
    display: inline-block;
}